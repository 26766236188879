import gql from 'graphql-tag';

/** ---------- AUTH ------------ */
export const login = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      id
      api_token
      email
      first_name
      last_name
    }
  }
`;




export const recoverUserLogin = gql`
  mutation recoverUserLogin($email: String!) {
    recoverUserLogin(email: $email){
      id
      error
    }
  }
`;

/** ---------- LOGOUT ------------ */

export const hardLogout = gql`
  mutation hardLogout($apiToken: String!) {
    hardLogout(apiToken: $apiToken)
  }
`;

export const logout = gql`
  mutation logout($apiToken: String!) {
    logout(apiToken: $apiToken)
  }
`;

/** ---------- USER ------------ */
export const createUser = gql`
  mutation createUser($input: CreateUserInput!) {
    createUser(input: $input) {
      id
      error
    }
  }
`;

export const updateUser = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      error
    }
  }
`;

export const deleteUser = gql`
  mutation deleteUser($id: ID!) {
    deleteUser(id: $id) {
      id
      error
    }
  }
`;

export const updateUserEmail = gql`
  mutation updateUserEmail($id: Int, $email: String!) {
    updateUserEmail(id: $id, email: $email) {
      id
      error
    }
  }
`;

export const updateUserPassword = gql`
  mutation updateUserPassword($id: Int, $password: String!) {
    updateUserPassword(id: $id, password: $password) {
      id
    }
  }
`;

/** ---------- ROLE ------------ */
export const createRole = gql`
  mutation createRole($input: CreateRoleInput!) {
    createRole(input: $input) {
      id
      error
    }
  }
`;

export const updateRole = gql`
  mutation updateRole($input: UpdateRoleInput!) {
    updateRole(input: $input) {
      id
      error
    }
  }
`;


export const deleteRole = gql`
  mutation deleteRole($id: ID!) {
    deleteRole(id: $id) {
      id
      error
    }
  }
`;

/** ---------- MANUFACTURER --------- */
export const createManufacturer = gql`
  mutation CreateManufacturer(
    $name: String
    $commission_type: String
    $commission_percentage: String
    $status: Int
    $brands: [String]
    $margin_percentage: String
    $alt_name: String
    $type: Int
    $service_type: String
  ) {
    CreateManufacturer(
      input: {
        name: $name
        commission_type: $commission_type
        commission_percentage: $commission_percentage
        status: $status
        brands: $brands
        margin_percentage: $margin_percentage
        alt_name: $alt_name
        type: $type,
        service_type: $service_type
      }
    ) {
      id
      slug
    }
  }
`;

export const updateManufacturer = gql`
  mutation updateManufacturer(
    $id: ID
    $name: String
    $commission_type: String
    $commission_percentage: String
    $status: Int
    $brands: [String]
    $margin_percentage: String
    $alt_name: String
    $type: Int
    $service_type: String
    $advertising_status: Int
    $advertising_channel_id: Int
    $ppc: String
    $dsp: String
    ) {
    updateManufacturer(
      input: {
        id: $id
        name: $name
        commission_type: $commission_type
        commission_percentage: $commission_percentage
        status: $status
        brands: $brands
        margin_percentage: $margin_percentage
        alt_name: $alt_name
        type: $type
        service_type: $service_type
        advertising_status: $advertising_status
        advertising_channel_id: $advertising_channel_id
        ppc: $ppc
        dsp: $dsp
      }
    ) {
      id
      error
    }
  }
`;

export const deleteManufacturer = gql`
  mutation deleteManufacturer($id: ID!) {
    deleteManufacturer(id: $id) {
      id
    }
  }
`;

export const createBrand = gql`
  mutation CreateBrand($input: CreateBrandInput!) {
    CreateBrand(input: $input) {
      id
      slug
    }
  }
`;

export const updateBrand = gql`
  mutation updateBrand($input: UpdateBrandInput!) {
    updateBrand(input: $input) {
      error
    }
  }
`;

export const createSalesTarget = gql`
  mutation CreateSalesTarget($input: CreateSalesTargetInput!) {
    CreateSalesTarget(input: $input) {
      id
      period_date
      revenue
    }
  }
`;

export const updateSalesTarget = gql`
  mutation UpdateSalesTarget($input: UpdateSalesTargetInput!) {
    UpdateSalesTarget(input: $input) {
      error
    }
  }
`;

export const DeleteSalesTarget = gql`
  mutation DeleteSalesTarget($id: ID!) {
    DeleteSalesTarget(id: $id)
  }
`;


/** ---------- PRODUCT --------- */

export const createProduct = gql`
  mutation createProduct(
    $manufacturer_id: Int
    $brand_id: Int
    $status: Int
    $product_type_id: Int
    $product_name: String
    $product_details: String
    $product_contents: String
    $packaging_unit_id: Int
    $gtin: String
    $manufacturer_product_id: String
    $base_content_volume: Float
    $base_unit: Int
    $pzn: String
    $attributes: String
    $attribute_values: String
    $seasonality: String
    $biozid: String
    $inhaltsstoffe: String
    $allergene: String
    $lot_number_required: Int
    $expiration_date: Int
  ) {
    createProduct(
      input: {
        manufacturer_id: $manufacturer_id
        brand_id: $brand_id
        status: $status
        product_type_id: $product_type_id
        product_name: $product_name
        product_details: $product_details
        product_contents: $product_contents
        packaging_unit_id: $packaging_unit_id
        gtin: $gtin
        manufacturer_product_id: $manufacturer_product_id
        base_content_volume: $base_content_volume
        base_unit: $base_unit
        pzn: $pzn
        attributes: $attributes
        attribute_values: $attribute_values
        seasonality: $seasonality
        biozid: $biozid
        inhaltsstoffe: $inhaltsstoffe
        allergene: $allergene
        lot_number_required: $lot_number_required
        expiration_date: $expiration_date
      }
    ) {
      product_parent_id
      slug
      error
    }
  }
`;

export const updateProduct = gql`
  mutation updateProduct(
    $product_parent_id: ID!
    $manufacturer_id: Int
    $brand_id: Int
    $status: Int
    $product_type_id: Int
    $product_name: String
    $product_details: String
    $product_contents: String
    $packaging_unit_id: Int
    $gtin: String
    $manufacturer_product_id: String
    $base_content_volume: Float
    $base_unit: Int
    $pzn: String
    $attributes: String
    $attribute_values: String
    $seasonality: String
    $biozid: String
    $inhaltsstoffe: String
    $allergene: String
    $lot_number_required: Int
    $expiration_date: Int
  ) {
    updateProduct(
      input: {
        product_parent_id: $product_parent_id
        manufacturer_id: $manufacturer_id
        brand_id: $brand_id
        status: $status
        product_type_id: $product_type_id
        product_name: $product_name
        product_details: $product_details
        product_contents: $product_contents
        packaging_unit_id: $packaging_unit_id
        gtin: $gtin
        manufacturer_product_id: $manufacturer_product_id
        base_content_volume: $base_content_volume
        base_unit: $base_unit
        pzn: $pzn
        attributes: $attributes
        attribute_values: $attribute_values
        seasonality: $seasonality
        biozid: $biozid
        inhaltsstoffe: $inhaltsstoffe
        allergene: $allergene
        lot_number_required: $lot_number_required
        expiration_date: $expiration_date
      }
    ) {
      product_parent_id
      warning
    }
  }
`;

export const updateSingleProduct = gql`
  mutation updateSingleProduct($input: UpdateSingleProductInput) {
    updateSingleProduct(input: $input) {
      product_parent_id
      warning
      errors
    }
  }
`

export const updateProductCategory = gql`
  mutation updateProductCategory(
    $product_parent_id: Int
    $product_category_pharmacy: String
    $product_category_hazmat: String
    $product_category_electronics: String
    $product_category_food: String
    $product_category_amazon: Int
    $product_category_ebay: Int
    $product_category_kaufland: Int
    $product_category_otto: Int
  ) {
    updateProductCategory(
      input: {
        product_parent_id: $product_parent_id
        product_category_pharmacy: $product_category_pharmacy
        product_category_hazmat: $product_category_hazmat
        product_category_electronics: $product_category_electronics
        product_category_food: $product_category_food
        product_category_amazon: $product_category_amazon
        product_category_ebay: $product_category_ebay
        product_category_kaufland: $product_category_kaufland
        product_category_otto: $product_category_otto
      }
    ) {
      id
    }
  }
`;

export const updateProductLogistic = gql`
  mutation updateProductLogistic($input: UpdateProductLogisticInput!) {
    updateProductLogistic(input: $input) {
      product_parent_id
      error
    }
  }
`;

export const updateProductMarketplaces = gql`
  mutation updateMarketplaceVendor(
    $input: [UpdateMarketplaceVendorInput!]
    $product_parent_id: Int
  ) {
    updateMarketplaceVendor(input: $input, product_parent_id: $product_parent_id) {
      id
      error
      validation_error
    }
  }
`;

export const updateProductPrices = gql`
  mutation updateMarketplacePrices(
    $input: [UpdateMarketplacePricesInput!]
    $product_parent_id: Int
  ) {
    updateMarketplacePrices(input: $input, product_parent_id: $product_parent_id) {
      id
    }
  }
`;

export const updateCustoms = gql`
  mutation updateCustom($input: [UpdateCustomInput!], $product_parent_id: Int) {
    updateCustom(input: $input, product_parent_id: $product_parent_id) {
      id
      error
    }
  }
`;

export const updatePharmacyInformation = gql`
  mutation updatePharmacyInformation($input: UpdatePharmacyInformationInput!, $product_parent_id: Int) {
    updatePharmacyInformation(input: $input, product_parent_id: $product_parent_id){
      biozid
    }
  }
`;

export const exportProduct = gql`
  mutation exportProduct($input: ExportProductInput) {
    exportProduct(input: $input)
  }
`;

export const importProduct = gql`
  mutation importProduct($input: ImportProductInput) {
    importProduct(input: $input) {
      id
      error
    }
  }
`;

export const ImportDocumentUpload= gql`
  mutation importDocumentUpload($input: ImportDocumentUploadInput) {
    importDocumentUpload(input: $input) {
      id
      error
    }
  }
`;

export const importProductCancel = gql`
  mutation importProductCancel($id: Int) {
    importProductCancel(id: $id) {
      id
    }
  }
`;

export const importProductConfirm = gql`
  mutation importProductConfirm($id: Int, $type: String) {
    importProductConfirm(id: $id, type: $type) {
      id
    }
  }
`;

/** ---------- PIM SETTINGS ------------ */

export const createMarketplace = gql`
  mutation CreateMarketplace($input: CreateMarketplaceInput!) {
    CreateMarketplace(input: $input) {
      id
    }
  }
`;

export const updateMarketplace = gql`
  mutation updateMarketplace($input: UpdateMarketplaceInput!) {
    updateMarketplace(input: $input) {
      error
    }
  }
`;

export const createFulfillmentVendor = gql`
  mutation createFulfillmentVendor($input: CreateFulfillmentVendorInput!) {
    createFulfillmentVendor(input: $input) {
      id
    }
  }
`;

export const updateFulfillmentVendor = gql`
  mutation updateFulfillmentVendor($input: UpdateFulfillmentVendorInput!) {
    updateFulfillmentVendor(input: $input) {
      error
    }
  }
`;

export const createProductType = gql`
  mutation createProductType($input: CreateProductTypeInput!) {
    createProductType(input: $input) {
      id
    }
  }
`;

export const updateProductType = gql`
  mutation updateProductType($input: UpdateProductTypeInput!) {
    updateProductType(input: $input) {
      error
    }
  }
`;

export const createPackagingUnit = gql`
  mutation createPackagingUnit($input: CreatePackagingUnitInput!) {
    createPackagingUnit(input: $input) {
      id
    }
  }
`;

export const updatePackagingUnit = gql`
  mutation updatePackagingUnit($input: UpdatePackagingUnitInput!) {
    updatePackagingUnit(input: $input) {
      error
    }
  }
`;

export const createProductCategoryTable = gql`
  mutation createProductCategoryTable($input: CreateProductCategoryTableInput!) {
    createProductCategoryTable(input: $input) {
      id
    }
  }
`;

export const updateProductCategoryTable = gql`
  mutation updateProductCategoryTable($input: UpdateProductCategoryTableInput!) {
    updateProductCategoryTable(input: $input) {
      error
    }
  }
`;

export const createBaseUnit = gql`
  mutation createBaseUnit($input: CreateBaseUnitInput!) {
    createBaseUnit(input: $input) {
      id
    }
  }
`;

export const updateBaseUnit = gql`
  mutation updateBaseUnit($input: UpdateBaseUnitInput!) {
    updateBaseUnit(input: $input) {
      error
    }
  }
`;

export const createArticlePackagingType = gql`
  mutation createArticlePackagingType($input: CreateArticlePackagingTypeInput!) {
    createArticlePackagingType(input: $input) {
      id
    }
  }
`;

export const updateArticlePackagingType = gql`
  mutation updateArticlePackagingType($input: UpdateArticlePackagingTypeInput!) {
    updateArticlePackagingType(input: $input) {
      error
    }
  }
`;

export const createSeller = gql`
  mutation createSeller($input: CreateSellerInput!) {
    createSeller(input: $input) {
      id
    }
  }
`;

export const updateSeller = gql`
  mutation updateSeller($input: UpdateSellerInput!) {
    updateSeller(input: $input) {
      error
    }
  }
`;

export const createShippingUnit = gql`
  mutation createShippingUnit($input: CreateShippingUnitInput!) {
    createShippingUnit(input: $input) {
      id
      name
    }
  }
`;

export const updateShippingUnit = gql`
  mutation updateShippingUnit($input: UpdateShippingUnitInput!) {
    updateShippingUnit(input: $input) {
      error
    }
  }
`;

export const createShippingCarrier = gql`
  mutation createShippingCarrier($input: CreateShippingCarrierInput!) {
    createShippingCarrier(input: $input) {
      id
      name
    }
  }
`;

export const updateShippingCarrier = gql`
  mutation updateShippingCarrier($input: UpdateShippingCarrierInput!) {
    updateShippingCarrier(input: $input) {
      error
    }
  }
`;

export const createBundleItem = gql`
  mutation createBundleItem($input: CreateBundleItemInput!) {
    createBundleItem(input: $input) {
      id
      error
    }
  }
`;

export const updateBundleItem = gql`
  mutation updateBundleItem($input: [UpdateBundleItemInput!]) {
    updateBundleItem(input: $input) {
      id
      error
    }
  }
`;

export const deleteBundleItem = gql`
  mutation deleteBundleItem($id: ID!, $product_parent_id: Int) {
    deleteBundleItem(id: $id, product_parent_id: $product_parent_id) {
      id
      error
    }
  }
`;

export const createPowerBISection = gql`
  mutation createPowerBISection($input: CreatePowerBISectionInput!) {
    createPowerBISection(input: $input) {
      id
      error
    }
  }
`;

export const updatePowerBISection = gql`
  mutation updatePowerBISection($input: UpdatePowerBISectionInput!, $id: Int, $updateSection: String) {
    updatePowerBISection(input: $input, id: $id, updateSection: $updateSection) {
      id
      error
    }
  }
`;


export const updateReport = gql`
  mutation updateReport($input: UpdateReportInput!, $id: Int) {
    updatePowerBISection(input: $input, id: $id) {
      id
      error
    }
  }
`;

export const updatePowerBI = gql`
  mutation updatePowerBI($input: UpdatePowerBIInput!) {
    updatePowerBI(input: $input) {
      id
      error
    }
  }
`;

export const removePowerBILink = gql`
  mutation removePowerBILink($id: Int!) {
    removePowerBILink(id: $id) {
      id
      error
    }
  }
`;

export const bulkEditStatus = gql`
  mutation bulkEditStatus($pids: [Int], $status: Int) {
    bulkEditStatus(pids: $pids, status: $status)
  }
`;


export const deleteProduct = gql`
  mutation deleteProduct($id: [ID!], $softDelete: Boolean){
      deleteProduct(id: $id, softDelete: $softDelete){
          product_parent_id
          slug
          product_name
          product_details
          packagingUnit {
            id
            name
          }
          productMarketplaces {
            marketplace_product_id
            marketplace_id_type
          }
          status
          deleted
          isBundledItem
          error
          product_name
          hasParentProducts{
              parentProduct{
                  product_parent_id
                  product_name
              }
          }
          bundleItems{
              product{
                  product_parent_id
                  product_name
              }
          }
      }
  }
`;

export const deleteSection = gql`
  mutation deleteSection($id: ID!, $hardDelete: Boolean) {
    deleteSection(id: $id, hardDelete: $hardDelete) {
      id
      error
    }
  }
`;

export const updateContentSettings = gql`
  mutation updateContentSettings($input: UpdateContentSettings!) {
    updateContentSettings(input: $input) {
      id
      error
    }
  }
`;

export const updateListing = gql`
  mutation updateListing($input: UpdateListingInput!, $product_parent_id: Int!, $marketplace_id: Int!){
    updateListing(input: $input, product_parent_id: $product_parent_id, marketplace_id: $marketplace_id){
      id
      error
    }
  }
`;

export const deleteProductMarketplace = gql`
  mutation deleteProductMarketplace($input: DeleteProductMarketplaceInput, $pid: String){
    deleteProductMarketplace(input: $input, pid: $pid){
      id
      product_child_id
      error
      validation_error
    }
  }
`;

export const deleteProductPrice = gql`
  mutation deleteProductPrice($input: DeleteProductPriceInput, $pid: String){
    deleteProductPrice(input: $input, pid: $pid){
      id
      error
    }
  }
`;


export const updateTabsOrdering = gql`
  mutation updateTabsOrdering($input: UpdateTabsOrdering!) {
    updateTabsOrdering(input: $input) {
      id
      error
    }
  }
`;

export const updateSubsectionOrdering = gql`
  mutation updateSubsectionOrdering($input: UpdateTabsOrdering!) {
    updateSubsectionOrdering(input: $input) {
      id
      error
    }
  }
`;


export const createFulFillmentCost = gql`
  mutation createFulFillmentCost($input: CreateFulFillmentCostInput!) {
    createFulFillmentCost(input: $input) {
      id
    }
  }
`;

export const updateFulFillmentCost = gql`
  mutation updateFulFillmentCost($input: UpdateFulFillmentCostInput!) {
    updateFulFillmentCost(input: $input) {
      error
    }
  }
`;

export const createWarehouseCost = gql`
  mutation createWarehouseCost($input: CreateWarehouseCostInput!) {
    createWarehouseCost(input: $input) {
      id
    }
  }
`;

export const updateWarehouseCost  = gql`
  mutation updateWarehouseCost($input: UpdateWarehouseCostInput!) {
    updateWarehouseCost(input: $input) {
      error
    }
  }
`;


export const createMarketplaceCost = gql`
  mutation createMarketplaceCost($input: CreateMarketplaceCostInput!) {
    createMarketplaceCost(input: $input) {
      id
    }
  }
`;

export const updateMarketplaceCost  = gql`
  mutation updateMarketplaceCost($input: UpdateMarketplaceCostInput!) {
    updateMarketplaceCost(input: $input) {
      error
    }
  }
`;


export const createAttribute = gql`
  mutation createAttribute($input: CreateAttributeInput!) {
    createAttribute(input: $input) {
      id
      error
    }
  }
`;

export const updateAttribute  = gql`
  mutation updateAttribute($input: UpdateAttributeInput!) {
    updateAttribute(input: $input) {
      id
      error
    }
  }
`;


export const createLinkedItem = gql`
  mutation createLinkedItem($input: CreateLinkedItemInput!) {
    createLinkedItem(input: $input) {
      id
      error
    }
  }
`;

export const updateLinkedItem  = gql`
  mutation updateLinkedItem($input: UpdateLinkedItemInput!) {
    updateLinkedItem(input: $input) {
      id
      error
    }
  }
`;

export const deleteLinkedItem  = gql`
  mutation deleteLinkedItem($input: DeleteLinkedItemInput!) {
    deleteLinkedItem(input: $input) {
      id
      error
  }
}
`;


export const deleteManufacturerAds = gql`
  mutation deleteManufacturerAds($id: [ID!]){
    deleteManufacturerAds(id: $id){
      id
    }
  }
`

export const createManufacturerPortfolio = gql`
  mutation createManufacturerPortfolio($input: CreateManufacturerPortfolioInput!){
    createManufacturerPortfolio(input: $input){
      id
      error
    }
  }
`

export const updateManufacturerPortfolio = gql`
  mutation updateManufacturerPortfolio($input: UpdateManufacturerPortfolioInput!){
    updateManufacturerPortfolio(input: $input){
      id
      error
    }
  }
`

export const deleteManufacturerPortfolio = gql`
  mutation deleteManufacturerPortfolio($id: [ID!]){
    deleteManufacturerPortfolio(id: $id){
      id
    }
  }
`

export const assignTags = gql`
  mutation assignTags($tags: [String!], $pid: [ID]!){
    assignTags(tags: $tags, pid: $pid){
      product_parent_id
    }
  }
`
